<template>
  <div class="app-body">
    <div class="wrapper w-100 m-3">
      <div class="animated fadeIn">
        <b-row class="justify-content-center">
          <b-col xl="8" lg="10">
            <b-card header-tag="header" footer-tag="footer">
              <div slot="header">
                <i class="fa fa-bell-o"></i><strong> Notifications</strong>
                <info-message>
                  Les notifications vous informent chaque fois qu'une action liée à vous est effectuée dans la plateforme.
                </info-message>
              </div>

              <div class="text-center" v-if="fetchingRead">
                <b-spinner></b-spinner>
                <br>
                Chargement...
              </div>
              <div v-else-if="notifications.length">
                <nav>
                  <b-pagination :total-rows="notifications.length" :per-page="perPage" v-model="currentPage"
                                prev-text="Préc." next-text="Suiv." hide-goto-end-buttons/>
                </nav>
                <b-alert v-for="notification in currentPageNotifications" show :variant="notification.variant">
                  <h4 class="alert-heading">{{notification.title}}</h4>
                  <p>
                    {{notification.message}}
                  </p>
                  <hr>
                  <p class="mb-0">
                    {{notification.createdAt}}
                  </p>
                </b-alert>
                <nav>
                  <b-pagination :total-rows="notifications.length" :per-page="perPage" v-model="currentPage"
                                prev-text="Préc." next-text="Suiv." hide-goto-end-buttons/>
                </nav>
              </div>
              <p class="text-center" v-else>
                Vous n'avez aucune notification.
              </p>

            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
  import {Api, Toast, Storage, String} from "../../helpers"

  export default {
    name: 'Notifications',
    title: 'PIA - Notifications',
    data() {
      return {
        fetchingRead: false,
        error: null,
        perPage: 10,
        currentPage: 1,
      }
    },
    mounted() {
      if(this.unreadNotifications.length)
      {
        this.fetchingRead = true
        Api.post('/user/notification/read', {
          notifications: this.unreadNotifications.map(item => item.id)
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {

            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingRead = false
          })
      }
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      },
    },
    computed: {
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      },
      unreadNotifications() {
        return this.$store.getters.unreadNotifications
      },
      notifications() {
        return this.$store.state.notifications.map(item => ({
          ...item,
          variant: item.isRead ? 'secondary' : item.isAlert ? 'danger' : 'info'
        }))
      },
      currentPageNotifications() {
        const start = (this.currentPage - 1) * this.perPage
        const end = start + this.perPage
        return this.notifications.slice(start, end)
      }
    },
    methods: {}
  }
</script>

<style scoped>

</style>
